<template>
 <div>
        <Header />
        <br /><br /><br /><br /><br /><br /><br />
        <div class="container fluid">
          <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/accessoiries/km100">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;;"
              class="card-img-top img-fluid"
              src="https://www.kmcorporate.com/wp-content/uploads/2021/06/KM100-rev.1-210609-DEF-1536x1044.jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                KM100
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/accessoiries/km6020">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://www.kmcorporate.com/wp-content/uploads/2021/07/KM6020-aperto-rev.1-210611-DEF-1536x1044.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                KM6020
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      
  
  
      </div>
     
      </div>
        <Footer />
    </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>